import { useEffect, useState, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { CurrentAuthStateContext } from '../App'
import { CurrentModeStateContext } from '../App';

import { useNavigate } from 'react-router-dom';


export default function SuccessRedirect() {

  const navigate = useNavigate();


  const auth = getAuth();

  const [userPayload, setUserPayload] = useState('');

  const [inTestingMode, setInTestingMode] = useState(false);
  const [logMessages, setLogMessages] = useState([]);



  const currentAuthStateContext = useContext(CurrentAuthStateContext);
  const currentModeStateContext = useContext(CurrentModeStateContext);

  // Function to send a message to the parent window when running in a webpage context
  function sendMessageToParent(data) {
    // Send the message to the parent window

    window.parent.postMessage(data, 'http://localhost:8080'); // for local development - use the PORT of the portal local
    window.parent.postMessage(data, 'https://portal.aistudio.com'); // for deployment

    //This is pretty insecure if we do this, but it will work for local testing
    //window.parent.postMessage(data, '*');
  }

  useEffect(() => {

    if (currentAuthStateContext.hint === '') {
      return;
    }

    if (currentModeStateContext.mode === 'unknown') {
      return;
    }
    

    let newValue = `In useEffect (57) ==>  currentModeStateContext=> ${JSON.stringify(currentModeStateContext)} `;
    setLogMessages(prevLogMessages => [...prevLogMessages, newValue]);



    //We call the CF services here to either create a new profile or return the existing
    //userProfileRef for us
    const manageUserProfile = async (uid, email, hint) => {

      let newValue = `In manageUserProfile ==>  UUID=> ${uid} || email=> ${email} || hint ${hint}`;
      setLogMessages(prevLogMessages => [...prevLogMessages, newValue]);


      try {


        let authBody = {
          uid: uid,
          mode: currentModeStateContext.mode,
          email: email,
          hint: hint
        }

        console.log('Auth Object', JSON.stringify(authBody));


        let address = "https://auth-guard.aistudio.com/api/1.0.0/auth/signin"


        const authPost = await fetch(address, {
          method: 'post',
          body: JSON.stringify(authBody)
        })

        console.log('Auth Post Returned', JSON.stringify(authPost));

        if (authPost.ok) {

          const responseBody = await authPost.text();
          console.log('Response Body', responseBody);

          if (responseBody !== 'NotStaff') {
            return responseBody;
          } else {
            console.log('User is not staff=>', responseBody);
          }


        } else {
          console.log('Error in Call=>', authPost.text());
        }


        //generic error return when not staff
        return 'error';

      } catch (error) {
        console.log('Error in manageUserProfile=>', JSON.stringify(error));

        return 'error';
      }

    }


    const getAuthToken = async () => {
      await auth.currentUser.getIdToken(true)
        .then(
          async function (idToken) {  // <------ Check this line
            //token = idToken; // It shows the Firebase token now
            console.log('got the token=>', idToken);


            console.log('Deeper Provider Email', auth.currentUser.providerData[0].email);
            console.log('Auth State Hint=>', currentAuthStateContext.hint);
            console.log('Auth State Mode=>', currentModeStateContext.mode);

            let newValue = `In getAuthToken ==>  email=> ${auth.currentUser.providerData[0].email} || hint ${currentAuthStateContext.hint}`;
            setLogMessages(prevLogMessages => [...prevLogMessages, newValue]);


            //Let the server manage the UID and get back the userProfileRef
            const workerResult = await manageUserProfile(
              auth.currentUser.uid,
              auth.currentUser.providerData[0].email,
              currentAuthStateContext.hint
            );

            console.log('workerResult=>', workerResult);

            if (workerResult !== 'error' && workerResult !== 'NotStaff') {

              console.log('workerResult SUCCESS=>', workerResult);

              //We NEVER send the UID to any of the clients (AIS Core or Portal etc).
              //The UID is set into the users profile. The token
              //validation functions will check the UID in the profile
              //where the token is validated against a userProfileRef
              let payload = {
                userProfileRef: workerResult,
                email: auth.currentUser.providerData[0].email,
                token: idToken,
                hint: currentAuthStateContext.hint
              }


              //We dont exit until this payload is set
              setUserPayload(payload);
            } else {
              throw new Error('Failure to register user token');
            }
          });
    }


    getAuthToken();


    // Clean up the effect (optional)
    // This will be called when the component unmounts
    return () => {
      // Add any cleanup code here if needed
    };



  }, [auth.currentUser, currentAuthStateContext, currentModeStateContext]);








  useEffect(() => {

    if (userPayload === '') {
      return;
    }

    let newValue = `In useEffect 182 ==>  userPayload=> ${JSON.stringify(userPayload)}`;
    setLogMessages(prevLogMessages => [...prevLogMessages, newValue]);


    //Grab the user information to return as a JSON object string.
    //THIS MUST BE ONLY IN THIS FORMAT
    //  userProfileRef, <=== AIStudio UserProfileRef
    //  email, 
    //  token, 
    //  hint


    // Convert the sendingValue to a JSON string (if it's not already)
    const jsonString = JSON.stringify(userPayload);

    try {


      // Send the message through ipcRender
      if (currentModeStateContext.mode === 'core') {
        console.log(' IPCRenderer Sending:', jsonString);
        // Running in Electron, use window.ipcRenderer to send the IPC message
        window.ipcRenderer.send('message:loginSuccessful', jsonString);
      } else { //portal
        // Fallback for regular web browser environment
        console.log('Web Sending:', jsonString);
        // Implement your custom logic here to handle the message sending

        sendMessageToParent({ type: 'authSuccess', data: jsonString });
      }


    } catch (error) {

      let newValue = `In useEffect 182 ==>  error=> ${JSON.stringify(error)}`;
      setLogMessages(prevLogMessages => [...prevLogMessages, newValue]);

    }


  }, [userPayload, currentModeStateContext])


  const testingLogout = () => {

    
    //Comment these two lines out when running in testing mode.... fucking warning shyte stops the build
    if(inTestingMode === false)
      setInTestingMode(true);


    auth.signOut();
    navigate('../emailsignup');
  }

  return (
    <div className="success-content">Completing Authentication...


      {/* TESTING ONLY - LOGOUT  */}

      {
        (inTestingMode === true)
        &&
        <>

          <div className="about">

            <button
              onClick={testingLogout}
            >
              Log out
            </button>
          </div>

          <div>
            {logMessages.length > 0 ? (
              <div>
                <h2>Log Messages:</h2>
                <ul>
                  {logMessages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <p>No log messages available.</p>
            )}
          </div>
        </>
      }

    </div>
  )
}
