import React, { useEffect, useState, useContext, useCallback } from 'react';
import {CurrentAuthStateContext} from '../App'
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { useNavigate} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { firebaseConfig } from '../firebaseAuth/firebaseConfig';

import { GoogleLoginButton } from "react-social-login-buttons";
import {updateAuthState} from '../observables/authStateObservable'

firebase.initializeApp(firebaseConfig);


//FIND ALL OF THESE HERE
//https://github.com/firebase/quickstart-js/blob/master/auth/google-redirect.html




const GoogleAuth = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [userLoggedIn, setUserLoggedIn] = useState('unknown');

  const currentAuthStateContext = useContext(CurrentAuthStateContext);
  

  const [signInInitiated, setSignInInitiated] = useState(false);

  useEffect(()=>{
    if(currentAuthStateContext === undefined){
      return;
    }

    setUserLoggedIn(currentAuthStateContext.state);

  },[currentAuthStateContext]);


  


  useEffect(() => {
    const handleRedirectResult = async () => {

      try {
        const result = await getRedirectResult(auth);

        if (result !== null) {



          if (result.credential) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const token = result.credential.accessToken;
            console.log('auth token =>', token);
          } else {
            console.log('NO auth token');
          }

          const user = result.user;
          if (user) {
            console.log('user is in =>', user);
            if (!userLoggedIn) {
              updateAuthState('in', 'google');
            }
          }
        } else {
          //The user is not even tried to log in yet..
          updateAuthState('out', 'google');
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleRedirectResult();
  }, [auth, userLoggedIn]);

  

  const doSignIn =useCallback(() => {
    
    try {
      if (!signInInitiated) {
        setSignInInitiated(true);
        if (!firebase.auth().currentUser) {
          const provider = new GoogleAuthProvider();
          provider.addScope('https://www.googleapis.com/auth/plus.login');
          provider.addScope('email');
          signInWithRedirect(auth, provider);
        } else {
          firebase.auth().signOut();

        }
      }
    } catch (error) {
      console.log('Error =>', error);
    }
  }, [auth, signInInitiated]);



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setSignInInitiated(true);
        navigate('../success');

        console.log('user is in =>', user);
        if (!userLoggedIn) {
          updateAuthState('in', 'google');
        }
      } else {
        console.log('user is out');
        if (signInInitiated) {
          doSignIn();
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, userLoggedIn,doSignIn, navigate,signInInitiated ]);

  return (

    <>
      {
        (userLoggedIn === 'unknown')
          ?
          <div></div>
          :
          <>
            {

              (signInInitiated === true)
                ?
                <div className="auth-initiating-content">Authenticating..</div>
                : (signInInitiated === false)
                &&
                <div className='primary-panel'>
                  <div className='signin-panel'>
                    <h2>Ready when you are!</h2>
                    <p>Let's get you signed in.</p>
                    <br />
                  </div>

                  <div className='signInButton'>
                    <GoogleLoginButton
                      onClick={doSignIn}
                    >
                      Sign in with Google
                    </GoogleLoginButton>
                  </div>
                </div>
            }
          </>

      }

    </>


  );
};

export default GoogleAuth;
