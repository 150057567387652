import { useNavigate } from 'react-router-dom';




export default function Register() {

  //NOTES:
  //The user has hit this page, because they have no identifier to indicate that they
  //have ever registered the account before
  const navigate = useNavigate();

  const handleIconButtonClick = (e, providerName)=>{

    e.preventDefault();

    console.log(providerName);

    switch (providerName) {
      case 'email':
        navigate('../logout');  
        break;
      case 'google':
        navigate('../googleregister');        
        break;
      default:
        break;
    }

  }

  return (
    <div className="primary-panel">      
      <h2>Register Provider.</h2>
      <p>Choose which authentication provider you would like to sign in with below.</p>
      <br/>
      

      <div className="provider-options">
        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'email')}
        >
          <div className='provider-button-content'>
          <img src='/images/Email_40x40.png' alt=''/>          
          <p>Email</p>          
          </div>
        </button>

        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'google')}
        >
          <div className='provider-button-content'>
          <img src='/images/Google_40x40.png' alt=''/>          
          <p>Google</p>
          </div>
        </button>
        

      </div>
      
    </div>
  )
}
