import { BehaviorSubject } from "rxjs";


/**
The subject is converted to an Observable when a subscriber pre-filters the pipe.
In order to set state back into this, a subject would need to be subscribed raw and a next call made
 */
export const authState$ = new BehaviorSubject  ({ state: 'unknown' , hint:''});

authState$.subscribe(
    (data) => {
        testStateInstruction(data);
    }
)

function testStateInstruction(output) {
    console.log("Auth State output => " + JSON.stringify( output));
}




export async function updateAuthState(state, hint) {
    var returnState = {
        state: state,
        hint:hint
    };

    // Emit the updated state using the authState$ Subject
    authState$.next(returnState);

    return returnState;
}