import React, { useEffect, useState } from 'react';
import { styled } from "@mui/system";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from '../firebaseAuth/firebaseConfig';

import { Controller, useForm } from 'react-hook-form';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const firebaseApp = firebase.initializeApp(firebaseConfig);


//FIND ALL OF THESE HERE
//https://github.com/firebase/quickstart-js/blob/master/auth/google-redirect.html



const FormItemInput = styled("div")(
    ({ theme }) => `
      display: flex;
      flex-direction: column;
      flex-shrink: 1;  
      //background-color:pink;
      //margin:5px;  
      margin-bottom:25px;
      // min-width:800px;
      // min-height:800px;
  
      `
);

const FormErrorLabel = styled("div")(
    ({ theme }) => `
    color: #BF2D22;
    
    margin-top: 0px;
    margin-bottom: auto;
    margin-left: 0px;
    //margin-right: 0px;
    display: flex;
    flex-grow: 0;
    justify-content: flex-start;
    align-items: center;
  
    font-family: 'poppins';
    font-weight: 400;
    font-size: calc(12px + (16 - 12) * ((100vw - 1366px) / (2560 - 1366)));
  
  
    `
);



const EmailForgot = () => {
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [signInInitiated, setSignInInitiated] = useState(false);
    const [showSignInFailed, setShowSignInFailed] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [email, setEmail] = useState('');

    const defaultValues = {
        email_address: email
    };


    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;



    const {
        control,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm({ defaultValues, mode: 'all' });


    const getFormErrorMessage = (name) => {

        return errors[name]
            ?
            <FormErrorLabel className="p-error">
                {errors[name].message}
            </FormErrorLabel>
            :
            <FormErrorLabel className="p-error">
                &nbsp;
            </FormErrorLabel>;
    };



    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            //console.log(value, name, type)
            const isEmailValid = !errors.email_address;
            const isPasswordValid = !errors.password;
            
            let isValid = isEmailValid && isPasswordValid;

            if (value.email_address === '') {
                isValid = false;
            }
           
            console.log('IsValid', isValid);

            if (isValid === true) {
                setEmail(value.email_address);
            }

            setIsFormValid(isValid);


            if (name === 'email_address') {
                console.log('Email Address Changed=>', value)
            }

        });
        return () => subscription.unsubscribe();
    }, [watch, errors.email_address, errors.password]);




    const handleFormSubmit = async () => {

        setSignInInitiated(true);
        try {
            const result = await sendPasswordResetEmail(auth, email);
            console.log('result =>', result);
        } catch (error) {
            setShowSignInFailed(true);
            setSignInInitiated(false);
            // Handle Errors here.
            var errorCode = error.code;
            //var errorMessage = error.message;
            if (errorCode === 'auth/invalid-email') {
                // alert('The password is too weak.');
            } else {
                //alert(errorMessage);
            }
            console.log(error);


        }
    };









    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('user is in =>', user);

                //navigate to success here
                navigate('../success');

            } else {
                console.log('user is out');
            }
        });

        return () => {
            unsubscribe();
        };
    }, [auth, navigate]);

    const handleGoToSignIn = ()=>{
        navigate('../emailauth');
    }


    return (
        <div className='primary-panel'>
            {
                (showSignInFailed === true)
                    ?
                    <div className='signin-panel'>
                        <h2>Houston - we have a problem!</h2>
                        <p>That did not go to plan. Please select Change Provider above and try again, or get in contact with us on support to get further help.</p>
                        <br />
                    </div>

                    :
                    (signInInitiated === false)
                        ?
                        <>


                            <div className='signin-panel'>
                                <h2>Password Reset.</h2>
                                <p>Let's reset it for you!</p>
                                <br />
                            </div>

                            <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-column gap-2">
                                <div className='form-signup'>

                                    <p>Email address</p>
                                    <Controller
                                        name='email_address'
                                        control={control}
                                        rules={{
                                            required: 'Email address is required.',
                                            pattern: {
                                                value: emailRegex,
                                                message: 'Invalid email address.',
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <FormItemInput>
                                                <span className="p-float-label">
                                                    <InputText
                                                        id={field.name}
                                                        value={field.value}
                                                        className={classNames({ 'p-invalid': fieldState.error })}
                                                        onChange={(e) => field.onChange(e.target.value)} />
                                                </span>
                                                {getFormErrorMessage(field.name)}
                                            </FormItemInput>
                                        )}
                                    />


                                    <div className='submit-button-container'>
                                        <Button
                                            label="Send Reset Mail"
                                            type="submit"
                                            disabled={!isFormValid}
                                            icon="pi pi-check" 
                                            severity="danger"
                                            style={{color:'white'}}
                                        />
                                        
                                    </div>


                                </div>
                            </form>
                        </>
                        :
                        <>
                            <div className='signin-panel'>
                                <h2>It's in the mail!</h2>
                                <p>We've sent you a password reset email.</p>
                                <br />
                            </div>

                            <div className='submit-button-container'>
                                <Button
                                onClick={handleGoToSignIn}
                                    label="Sign In"
                                    icon="pi pi-check"
                                    severity="info"
                                    style={{ color: 'white' }}
                                />

                            </div>
                        </>


            }

        </div>
    );
};

export default EmailForgot;
