import { Outlet, NavLink, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from 'react'
import { CurrentAuthStateContext } from '../App'
import { CurrentModeStateContext } from '../App';

export default function RootLayout() {

  const location = useLocation();
  const currentPath = location.pathname;

  const [userLoggedIn, setUserLoggedIn] = useState('unknown');

  const currentAuthStateContext = useContext(CurrentAuthStateContext);
  const currentModeStateContext = useContext(CurrentModeStateContext);

  useEffect(() => {
    if (currentAuthStateContext === undefined) {
      return;
    }

    setUserLoggedIn(currentAuthStateContext.state);

  }, [currentAuthStateContext]);


  return (
    <div className="root-layout">

      <div className="logo-header">
        <img className="logo-image" src='/images/AISLogo_60.png' alt="" />
      </div>
      <header>
        <nav>
          {
            (currentPath === '/')
              ?
              <div></div>
              : (currentPath === '/switch')
                ?
                <div></div>
                : (currentPath === '/success')
                  ?
                  <div></div>
                  : (currentPath === '/emailauth')
                    ?
                    <>
                      {
                        (currentModeStateContext.mode !== 'portal')
                          &&

                          (userLoggedIn !== 'unknown')
                          ?
                          <div>
                            <NavLink to="switch">Change Provider</NavLink>
                            <NavLink to="emailforgot">Forgot Password</NavLink>
                            <NavLink to="emailsignup">Sign Up</NavLink>
                          </div>
                          : <div></div>
                      }
                    </>

                    : (currentPath === '/emailforgot')
                      ?
                      <>
                        {
                          (currentModeStateContext.mode !== 'portal')
                          &&
                          <NavLink to="switch">Change Provider</NavLink>
                        }
                      </>
                      : (currentPath === '/emailsignup')
                        ?
                        <>
                          {
                            (currentModeStateContext.mode !== 'portal')
                            &&
                            <NavLink to="switch">Change Provider</NavLink>
                          }
                        </>
                        :
                        <>

                          {
                            (currentModeStateContext.mode !== 'portal')
                              &&
                              (userLoggedIn !== 'unknown')
                              ? <NavLink to="switch">Change Provider</NavLink>
                              : <div></div>
                          }
                        </>

          }
          {/* <NavLink to="/">Home</NavLink>
          <NavLink to="about">About</NavLink> */}

        </nav>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  )
}
