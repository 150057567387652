import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from '../firebaseAuth/firebaseConfig';

import { GoogleLoginButton } from "react-social-login-buttons";

//initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);


const Login = props => {

    
    const navigate = useNavigate();
    const [userLoggedIn,setUserLoggedIn] = useState(false);



      // Listening for auth state changes.
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          console.log('user is in=>',user);
          setUserLoggedIn(true);
         
        } else {
            console.log('user is out');         
        }
        
      });

    const doSignIn = async()=>{
        navigate('../googleauth');
    }


    useEffect(() => {
      if(userLoggedIn === false){
        return;
      }

      navigate('../about');
    
      return () => {
        
      }
    }, [userLoggedIn, navigate])
    



    return (
        <div>
            <h1>Login</h1>
            <GoogleLoginButton
            onClick={doSignIn}
            >
                Login
            </GoogleLoginButton>


        </div>

    );
};


export default Login;