import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseAuth/firebaseConfig'
import {authState$} from './observables/authStateObservable'
import { modeState$ } from './observables/modeStateObservable'
// pages
import Home from './pages/home'
import Register from './pages/register'
import SwitchProvider from './pages/switchProvider'
import Logout from './pages/logout'
import Login from './pages/login'
import SuccessRedirect from './pages/successRedirect'

import EmailForgot from './pages/emailForgot'
import EmailAuth from './pages/emailAuth'
import EmailSignUp from './pages/emailSignup'

import GoogleAuth from './pages/googleAuth'
import GithubAuth from './pages/githubAuth'

// layouts
import RootLayout from './layouts/RootLayout'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { createContext, useState, useLayoutEffect } from 'react'


export const firebaseApp = initializeApp(firebaseConfig);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index  element={<Home />} />
      <Route path="register" element={<Register />} />

      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="success" element={<SuccessRedirect />} />
      <Route path="emailforgot" element={<EmailForgot />} />

      <Route path="googleauth" element={<GoogleAuth />} />      
      <Route path="githubauth" element={<GithubAuth />} />  




      <Route path="emailauth" element={<EmailAuth />} />
      <Route path="emailsignup" element={<EmailSignUp />} />
      <Route path="switch" element={<SwitchProvider />} />


    </Route>
  )
)


export const CurrentAuthStateContext = createContext({ state: 'unknown' , hint: 'unknown'});
export const CurrentModeStateContext = createContext({ mode: 'unknown'});

function App() {

  //NOTES:
  //We want to grab any values sent to this site which indicate
  //the user has previously created an account and by which auth provider type
  //We will have to push that into context, so the home page which loads by default
  //as the index, can redirect to the relevent provider page.

  const [authState, setAuthState] = useState({ state: 'unknown' });
  const [modeState, setModeState] = useState({ mode: 'unknown' });

  useLayoutEffect(() => {
    const AuthState$ = authState$.subscribe(setAuthState);
    const ModeState$ = modeState$.subscribe(setModeState);
  
    return () => {
      AuthState$.unsubscribe();
      ModeState$.unsubscribe();
    };
  }, [])

  // useEffect(()=>{
  //   if(authState === undefined){
  //     return;
  //   }

  // },[authState]);


  return (
    <CurrentModeStateContext.Provider value={modeState} >
      <CurrentAuthStateContext.Provider value={authState} >
        <RouterProvider router={router} />
      </CurrentAuthStateContext.Provider>
    </CurrentModeStateContext.Provider>
  );
}

export default App
