// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCJr2lQbxn6VPS3wzTc1tD18nT-yX9Gir8",
  authDomain: "auth-aistudio.firebaseapp.com",
  projectId: "auth-aistudio",
  storageBucket: "auth-aistudio.appspot.com",
  messagingSenderId: "147146620159",
  appId: "1:147146620159:web:11af28647dce7b945d20ca",
  measurementId: "G-RNNPF83KEK"
};

// Initialize Firebase
//const FBApp = initializeApp(firebaseConfig);
//export const FBUi = new firebaseui.auth.AuthUI(FBApp);


//Working for UI
// const FBApp = firebase.initializeApp(firebaseConfig);
// export const FBUi = new firebaseui.auth.AuthUI(firebase.auth());

// const analytics = getAnalytics(app);