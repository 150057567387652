import { useNavigate } from 'react-router-dom';




export default function SwitchProvider() {

  //NOTES:
  //The user has hit this page, because they have no identifier to indicate that they
  //have ever registered the account before
  const navigate = useNavigate();

  const handleIconButtonClick = (e, providerName)=>{

    e.preventDefault();

    console.log(providerName);

    switch (providerName) {
      case 'email':
        navigate('../emailauth');  
        break;
      case 'google':
        navigate('../googleauth');        
        break;
      case 'github':
        navigate('../githubauth');        
        break;
      default:
        break;
    }

  }

  return (
    <div className="primary-panel">      
      <h2>Switching Providers.</h2>
      <p>You can change your Authentication Provider below to sign in with the correct social credentials.</p>
      <br/>
      <div>
        <strong>Note: Account Linking</strong>
        <p>Your account may automatically be linked to multiple social providers depending on how they share information.</p>
        <p>If you need an entirely fresh AIStudio profile (e.g. one for work and one for personal), you should create a new Email sign in using an email account that is not linked to any social sign in providers.</p>
        
      </div>

      <div className="provider-options">
        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'email')}
        >
          <div className='provider-button-content'>
          <img src='/images/Email_40x40.png' alt=''/>      
          <p>Email</p>          
          </div>
        </button>

        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'google')}
        >
          <div className='provider-button-content'>
          <img src='/images/Google_40x40.png' alt=''/>
          <p>Google</p>
          </div>
        </button>

        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'github')}
        >
          <div className='provider-button-content'>
          <img src='/images/Github_40x40.png' alt=''/>          
          <p>Github</p>
          </div>
        </button>
        

      </div>
      
    </div>
  )
}
