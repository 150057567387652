import React, { useEffect, useState, useContext } from 'react';
import { styled } from "@mui/system";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from '../firebaseAuth/firebaseConfig';

import { Controller, useForm } from 'react-hook-form';
import {updateAuthState} from '../observables/authStateObservable'

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CurrentModeStateContext } from '../App';

const firebaseApp = firebase.initializeApp(firebaseConfig);




//FIND ALL OF THESE HERE
//https://github.com/firebase/quickstart-js/blob/master/auth/google-redirect.html



const FormItemInput = styled("div")(
    ({ theme }) => `
      display: flex;
      flex-direction: column;
      flex-shrink: 1;  
      //background-color:pink;
      //margin:5px;  
      margin-bottom:25px;
      // min-width:800px;
      // min-height:800px;
  
      `
);

const FormErrorLabel = styled("div")(
    ({ theme }) => `
    color: #BF2D22;
    
    margin-top: 0px;
    margin-bottom: auto;
    margin-left: 0px;
    //margin-right: 0px;
    display: flex;
    flex-grow: 0;
    justify-content: flex-start;
    align-items: center;
  
    font-family: 'poppins';
    font-weight: 400;
    font-size: calc(12px + (16 - 12) * ((100vw - 1366px) / (2560 - 1366)));
  
  
    `
);



const EmailAuth = () => {

    const currentModeStateContext = useContext(CurrentModeStateContext);

    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [signInInitiated, setSignInInitiated] = useState(false);
    const [showSignInFailed, setShowSignInFailed] = useState(false);
    
    const [isFormValid, setIsFormValid] = useState(false);


    //Thank you goole, you twats... if a user has already created an account with a social
    //login, then try to create a email/password account, we will get a failure because
    //the user can only ever have a single UID regarless of the auth provider
    //it is possible to create linked credentials, but its a giant ass pain, so lets
    //skip this for now, and just tell them they need to use what they did last time
    //or use a different email address
    const [showLinkedAccountFail, setShowLinkedAccountFail] = useState(false);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const defaultValues = {
        email_address: email,
        password: password
    };


    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;



    const {
        control,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm({ defaultValues, mode: 'all' });


    const getFormErrorMessage = (name) => {

        return errors[name]
            ?
            <FormErrorLabel className="p-error">
                {errors[name].message}
            </FormErrorLabel>
            :
            <FormErrorLabel className="p-error">
                &nbsp;
            </FormErrorLabel>;
    };



    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            //console.log(value, name, type)
            const isEmailValid = !errors.email_address;
            const isPasswordValid = !errors.password;
            
            let isValid = isEmailValid && isPasswordValid;

            if (value.email_address === '') {
                isValid = false;
            }
            if (value.password === '') {
                isValid = false;
            }

            console.log('IsValid', isValid);

            if (isValid === true) {
                setEmail(value.email_address);
                setPassword(value.password);
            }

            setIsFormValid(isValid);


            if (name === 'email_address') {
                console.log('Email Address Changed=>', value)
            }

        });
        return () => subscription.unsubscribe();
    }, [watch, errors.email_address, errors.password]);




    const handleFormSubmit = async () => {
        updateAuthState('unknown', 'email');
        setSignInInitiated(true);
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            console.log('result =>', result);
        } catch (error) {
            updateAuthState('out', 'email');
            setShowSignInFailed(true);
            setSignInInitiated(false);
            // Handle Errors here.
            var errorCode = error.code;
            //var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                // alert('The password is too weak.');
            }else if (errorCode === 'auth/email-already-in-use'){
                setShowLinkedAccountFail(true);
            } else {
                //alert(errorMessage);
            }
            console.log('error-code=>',error.code);


        }
    };









    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {

            //alert('The logged in user => ' + JSON.stringify(user));

            if (user) {
                console.log('user is in =>', user);
                updateAuthState('in', 'email');
                //navigate to success here
                navigate('../success');

            } else {
                console.log('user is out');
                updateAuthState('out', 'email');
            }
        });

        return () => {
            unsubscribe();
        };
    }, [auth, navigate]);

    const handleGoSignUp = ()=>{
        navigate('../emailsignup');
    }

    return (
        <div className='primary-panel'>
            {
                (showSignInFailed === true)
                    ?
                    <>
                    {
                        (showLinkedAccountFail === false)
                        ?
                            <div className='signin-panel'>
                                <h2>Houston - we have a problem!</h2>
                                <p>That did not go to plan. Please select Change Provider above and try again, or get in contact with us on support to get further help.</p>
                                <br />
                            </div>
                        :
                            <div className='signin-panel'>
                                <h2>No Account Linking</h2>
                                <p>Unfortunately, it appears you have previously created an account using a Social Login that is bound to the same email address.</p>
                                <p>You can either try to remember what provider that was and login with that or use another email address to create a new account.</p>
                                <p>Please select Change Provider above and try again, or get in contact with us on support to get further help.</p>
                                <br />
                            </div>
                    }
                    </>

                    :
                    (signInInitiated === false)
                        ?
                        <>


                            <div className='signin-panel'>
                                <h2>You know the drill...</h2>
                                <p>Let's get you signed in.</p>
                                <br />
                            </div>

                            <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-column gap-2">
                                <div className='form-signup'>

                                    <p>Email address</p>
                                    <Controller
                                        name='email_address'
                                        control={control}
                                        rules={{
                                            required: 'Email address is required.',
                                            pattern: {
                                                value: emailRegex,
                                                message: 'Invalid email address.',
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <FormItemInput>
                                                <span className="p-float-label">
                                                    <InputText
                                                        id={field.name}
                                                        value={field.value}
                                                        className={classNames({ 'p-invalid': fieldState.error })}
                                                        onChange={(e) => field.onChange(e.target.value)} />
                                                </span>
                                                {getFormErrorMessage(field.name)}
                                            </FormItemInput>
                                        )}
                                    />

                                    <p>Password</p>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: 'Password is required.',
                                            minLength: {
                                                value: 8,
                                                message: 'Password should be at least 8 characters long.',
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <FormItemInput>
                                                <span className="p-float-label">
                                                    <Password
                                                        id={field.name}
                                                        value={field.value}
                                                        toggleMask
                                                        className={classNames({ 'p-invalid': fieldState.error })}
                                                        onChange={(e) => field.onChange(e.target.value)}
                                                    />
                                                </span>
                                                {getFormErrorMessage(field.name)}
                                            </FormItemInput>
                                        )}
                                    />


                                    <div className='submit-button-container'>
                                        <Button
                                            label="Sign In"
                                            type="submit"
                                            disabled={!isFormValid}
                                            icon="pi pi-check" 
                                            severity="info"
                                            style={{color:'white'}}
                                        />
                                        <br/>

                                        {
                                            (currentModeStateContext.mode !== 'portal')
                                            &&
                                            <>

                                                <h2> Don't have an account?</h2>
                                                <p>Create an account now</p>

                                                <Button
                                                    onClick={handleGoSignUp}
                                                    label="Sign Up"
                                                    icon="pi pi-arrow-circle-right"
                                                    severity="success"
                                                    style={{ color: 'white' }}
                                                />

                                            </>
                                        }

                                    </div>


                                </div>
                            </form>
                        </>
                        :
                        <div className='signin-panel'>
                            <h2>Signing you in...</h2>
                            <p>This shouldn't take long.</p>
                            <br />
                        </div>
            }

        </div>
    );
};

export default EmailAuth;
