import { BehaviorSubject } from "rxjs";


/**
The subject is converted to an Observable when a subscriber pre-filters the pipe.
In order to set state back into this, a subject would need to be subscribed raw and a next call made
 */
export const modeState$ = new BehaviorSubject  ({ mode: 'unknown'});

modeState$.subscribe(
    (data) => {
        testStateInstruction(data);
    }
)

function testStateInstruction(output) {
    console.log("Mode State output => " + JSON.stringify(output));
}




export async function updateModeState(mode) {
    var returnState = {
        mode: mode
    };

    // Emit the updated state using the authState$ Subject
    modeState$.next(returnState);

    return returnState;
}