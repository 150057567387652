import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { updateModeState } from '../observables/modeStateObservable';


export default function Home() {

  //NOTES:
  //The user always hits this page first
  //http://localhost:3000/?sessionid=555555&authhint=email&mode=core
  //http://localhost:3000/?sessionid=555555&authhint=google&mode=core
  //http://localhost:3000/?sessionid=555555&authhint=github&mode=portal

  //The can register or choose a provider if they're new,
  //otherwise we will forward them to their usual provider for signin


  
  const navigate = useNavigate();
  const domlocation = useLocation();
  
  const [searchParams,setSearchParams]=useState();

  const [currentSessionId, setCurrentSessionId] = useState('')  
  const [authHint, setAuthHint] = useState('')
  const [currentMode, setCurrentMode] = useState('')
  

  useEffect(()=>{

    // const searchParams = useCallback(()=> {
    //   new URLSearchParams(location.search)
    // },[location.search]);

    const foundParams = new URLSearchParams(domlocation.search);
    setSearchParams(foundParams);
    
    
  },[domlocation])

  useEffect(() => {
    
    try {

      if(searchParams === undefined){
        return;
      }
    
      var newSessionId = searchParams.get('sessionid');
      setCurrentSessionId(newSessionId);
  
      console.log('Recieved Session=>', newSessionId);

      var newAuthHint = searchParams.get('authhint');
      setAuthHint(newAuthHint);

      console.log('Recieved AuthHint=>', newAuthHint);

      var newMode = searchParams.get('mode');
      updateModeState(newMode);
      setCurrentMode(newMode);

      console.log('Recieved Mode=>', newMode);

  
    } catch (error) {
      console.log('Error in home param passing=>', JSON.stringify(error));
    }
  
    return () => {
    
    }
  }, [searchParams, setCurrentMode])

  
  useEffect(() => {
  
    if(currentSessionId === ''){
      return;      
    }
  
    if(authHint === ''){
      return;      
    }

    if(currentMode === ''){
      return;      
    }


    switch (authHint) {
      case 'google':
        navigate('googleauth');        
        break;
      case 'github':
        navigate('githubauth');        
        break;
      case 'email':
        navigate('emailauth');        
        break;
  
      default:
        break;
    }

    return () => {
    
    }
  }, [authHint,currentSessionId, navigate, currentMode])
  
  

  const handleIconButtonClick = (e, providerName)=>{

    e.preventDefault();

    console.log(providerName);

    switch (providerName) {
      case 'email':
        navigate('emailsignup');  
        break;
      case 'google':
        navigate('googleauth');        
        break;
      case 'github':
        navigate('githubauth');        
        break;
      default:
        break;
    }

  }

  return (
    <div className="primary-panel">
      <h1>Account Authentication</h1>
      <h2>Welcome to AIStudio.</h2>
      <p>We offer you a seamless and secure account creation process with flexible authentication options tailored to your convenience.</p>
      <p>You can choose to create an account using your email and password, ensuring complete control and privacy.</p>
      <p>Alternatively, enjoy a hassle-free experience by signing up with popular third-party providers, making registration quick and effortless. </p>
      <p>We're working to bring additional providers onboard soon.</p>

      <div className="provider-options">
        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'email')}
        >
          <div className='provider-button-content'>
          <img src='/images/Email_40x40.png' alt=''/>          
          <p>Email</p>          
          </div>
        </button>

        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'google')}
        >
          <div className='provider-button-content'>
          <img src='/images/Google_40x40.png' alt=''/>          
          <p>Google</p>
          </div>
        </button>

        <button className='provider-button'
          onClick={(e)=> handleIconButtonClick(e, 'github')}
        >
          <div className='provider-button-content'>
          <img src='/images/Github_40x40.png' alt=''/>          
          <p>Github</p>
          </div>
        </button>
        

      </div>
      
    </div>
  )
}
