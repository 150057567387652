import {useState, useEffect} from 'react';
import {getAuth, signOut} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function Logout() {


  
  const auth = getAuth();
  const navigate = useNavigate();
  const [isAuthed,setIsAuthed] = useState(true);

  const [userName,setUserName] = useState('');


  const handleLogOut= async ()=>{
    await signOut(auth);
    setIsAuthed(false);
  }

  useEffect(()=>{
    if(isAuthed === false){
      navigate('/');
      return;
    }

    if(auth.currentUser!==null)
      setUserName(auth.currentUser.email);

  },[isAuthed, navigate, auth.currentUser]);

  return (
    <div className="about">
      <h1>User =  {userName}</h1>
      <h2>About Us</h2>
      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui provident consequuntur vel omnis quisquam rem harum, maxime expedita, ullam ut dolore! Distinctio eos minima voluptatum totam id hic! Sapiente debitis quia illum officia obcaecati provident nulla odio molestiae suscipit quasi.</p>
      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui provident consequuntur vel omnis quisquam rem harum, maxime expedita, ullam ut dolore! Distinctio eos minima voluptatum totam id hic! Sapiente debitis quia illum officia obcaecati provident nulla odio molestiae suscipit quasi.</p>
      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui provident consequuntur vel omnis quisquam rem harum, maxime expedita, ullam ut dolore! Distinctio eos minima voluptatum totam id hic! Sapiente debitis quia illum officia obcaecati provident nulla odio molestiae suscipit quasi.</p>

      <button
      onClick={handleLogOut}
      >
        Log out
      </button>
    </div>
  )
}
